import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaMapMarkerAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styles from '../styles/components/footer.module.css';

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.leftContent}>
          <h3>Vamos <span>conversar?</span></h3>
          <p>Entre em contato com o nosso time para que juntos possamos encontrar a solução mais adequada para você!</p>
          <Link to="/budget" onClick={scrollToTop}>
            <button className={styles.contactButton}>Enviar uma mensagem</button>
          </Link>
        </div>
        <div className={styles.centerContent}>
          <button className={styles.backToTop} onClick={scrollToTop}>Voltar ao topo</button>
          <div className={styles.logo}>Linked</div>
          <ul className={styles.socialList}>
            <li><a href="https://www.linkedin.com/company/linked-ej/mycompany/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a></li>
            <li><a href="https://www.instagram.com/linked_ej/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a></li>
            <li><a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a></li>
          </ul>
        </div>
        <div className={styles.rightContent}>
          <div className={styles.location}>
            <FaMapMarkerAlt className={styles.locationIcon} />
            <p>Rodovia BR-494 - Campus Tancredo Neves, sala 1.12 - UFSJ, 36301-360 - São João Del-Rei - MG</p>
          </div>
        </div>
      </div>
      <p className={styles.copyRight}>
        Copyright &copy; <span>2024 Linked</span>
      </p>
    </footer>
  );
}

export default Footer;
