import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaMapMarkerAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styles from '../styles/components/footerBudget.module.css';

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
      <div className={styles.leftContent}>
        <div className={styles.logo}>Linked</div>
      </div>
        <div className={styles.centerContent}>
          <button className={styles.backToTop} onClick={scrollToTop}>Voltar ao topo</button>
        </div>
        <div className={styles.rightContent}>
          <ul className={styles.socialList}>
              <li><a href="https://www.linkedin.com/company/linked-ej/mycompany/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a></li>
              <li><a href="https://www.instagram.com/linked_ej/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a></li>
              <li><a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a></li>
          </ul>
        </div>
      </div>
      <p className={styles.copyRight}>
        Copyright &copy; <span>2024 Linked</span>
      </p>
    </footer>
  );
}

export default Footer;
