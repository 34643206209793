import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import styles from '../styles/layouts/memberCarousel.module.css';
import { NextArrow, PrevArrow } from '../components/Arrow';

const MemberCarousel = ({ members }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Reduzi para 3 para ter mais espaço por imagem
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className={styles.carouselContainer}>
      <Slider {...settings} className={styles.slider}>
        {members.map((member, index) => (
          <div key={index} className={styles.member}>
            <div className={styles.memberImage}>
              <img src={member.image} alt={member.name} />
            </div>
            <p className={styles.memberName}>{member.name}</p>
            <p className={styles.memberRole}>{member.role}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MemberCarousel;
