import React from 'react';
import styles from '../styles/layouts/aboutUsCompany.module.css';
import equipe from '../assets/equipe.png';

const AboutUsCompany = () => {
  return (
    <section className={styles.about_us_section}>
      <h2>Nossa História</h2>
      <div className={styles.about_content}>
        <div className={styles.text}>
          <p>A Linked é a Empresa Júnior do curso de Ciência da Computação da Universidade Federal de São João del-Rei. Criada e gerida por estudantes, nossa missão é oferecer soluções tecnológicas inovadoras, garantindo qualidade e satisfação ao cliente. Promovemos o desenvolvimento pessoal e profissional dos nossos membros através da execução de projetos de alta qualidade, contribuindo para o progresso econômico e empresarial.</p>
          <p>Valorizamos a inovação e o acolhimento, direcionando nossos lucros para criar oportunidades tanto para nossos membros quanto para a comunidade da computação. Compartilhamos nosso conhecimento para democratizar o acesso ao mercado de computação, trabalhando para construir um ambiente colaborativo e acessível a todos.</p>
        </div>
        <div className={styles.image_container}>
          <img src={equipe} alt="Mascote Linked" className={styles.about_image} />
          <div className={styles.data}>
            <div className={styles.item}>
                <h1>32</h1>
                <p>Projetos</p>
                <p>executados</p>
            </div>
            <div className={styles.item}>
                <h1>10</h1>
                <p>Anos de</p>
                <p>atividade</p>
            </div>
            <div className={styles.item}>
                <h1>32</h1>
                <p>Membros</p>
                <p>ativos</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
};

export default AboutUsCompany;