import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { NextArrow, PrevArrow } from '../components/Arrow';
import styles from '../styles/layouts/testimonials.module.css';

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className={styles.testimonials_section}>
      <h2>DEPOIMENTOS</h2>
      <div className={styles.title_underline}></div>
      <p>O que dizem nossos clientes?</p>
      <Slider {...settings} className={styles.slider}>
        <div className={styles.testimonial}>
          <h3>TIJOLO</h3>
          <h4>Empresa Júnior de Engenharia Civil da UFMG</h4>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus.</p>
          <div className={styles.nps}>
            <span>NPS: 9.0</span>
            <a href="#">Leia mais</a>
          </div>
        </div>
        <div className={styles.testimonial}>
          <h3>PRODTUDO</h3>
          <h4>Empresa Júnior de Engenharia de Produção da UFBA</h4>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus.</p>
          <div className={styles.nps}>
            <span>NPS: 9.0</span>
            <a href="#">Leia mais</a>
          </div>
        </div>
        <div className={styles.testimonial}>
          <h3>PROJECTA</h3>
          <h4>Empresa Júnior de Arquitetura e Urbanismo da UFBA</h4>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus.</p>
          <div className={styles.nps}>
            <span>NPS: 9.0</span>
            <a href="#">Leia mais</a>
          </div>
        </div>
        <div className={styles.testimonial}>
          <h3>EXTRA</h3>
          <h4>Empresa Júnior de Outro Curso UFExemplo</h4>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus.</p>
          <div className={styles.nps}>
            <span>NPS: 9.0</span>
            <a href="#">Leia mais</a>
          </div>
        </div>
      </Slider>
    </section>
  );
};

export default Testimonials;
