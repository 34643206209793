import React from 'react';
import styles from '../styles/pages/budget.module.css';
import avatarImage from '../assets/Membros/Atual/Braian.jpg';
import mapImage from '../assets/map.jpg';
import hoursImage from '../assets/relogio.png';

const BudgetDetails = () => {
  return (
    <div className={styles.budgetDetails}>
      <div className={styles.overlay}></div>
      <div className={styles.detailsLeft}>
        <div className={styles.personInfo}>
          <img src={avatarImage} alt="Lucas Costa" />
          <div>
            <h3>Lucas Costa</h3>
            <p>Diretor Comercial</p>
            <p>(38) 99808-9253</p>
            <p>linkedej@gmail.com</p>
          </div>
        </div>
        <div className={styles.hours}>
          <img src={hoursImage} alt="Horário" />
          <div>
            <h3>Nossos horários</h3>
            <p>Segunda à Sexta: 08:00 às 18:00</p>
            <p>Sábado: 08:00 às 16:00</p>
            <p>Domingo: Fechado</p>
          </div>
        </div>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.detailsRight}>
        <div className={styles.location}>
          <h3>Localização</h3>
          <p>Rodovia BR-494 - Campus Tancredo Neves, sala 1.12 - UFSJ, 36301-360 - São João Del-Rei - MG</p>
          <img src={mapImage} alt="Mapa" />
        </div>
      </div>
    </div>
  );
};

export default BudgetDetails;
