import React from 'react';
import Slider from "react-slick";
import styles from '../styles/layouts/partners.module.css';
import fundoReuniao from '../assets/fundo_reuniao.jpeg';
import parceiro from '../assets/forte_parceiros.png';


const Partners = () => {
  const images = [
    parceiro, parceiro, parceiro, parceiro, parceiro
  ];

  const settings = {
    dots: true, // Habilita os indicadores (dots)
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false, // Ativa o autoplay
    // autoplaySpeed: 3000,
    speed: 1500,
    cssEase: "linear",
    arrows: false, // Desativa as setas de navegação
  };

  return (
    <section className={styles.stats_section} style={{ backgroundImage: `url(${fundoReuniao})` }}>
      <div className={styles.overlay}></div>
      <div className={styles.stats_container}>
        <div className={styles.item}>
          <h1>Parceiros</h1>
          <hr className={styles.title_underline} />
          <p>Confira alguns dos nossos parceiros</p>
        </div>
        <div className={styles.slider_container}>
          <Slider {...settings}>
            {images.map((img, index) => (
              <div key={index} className={styles.slide}>
                <img src={img} alt={`parceiro ${index + 1}`} className={styles.partner_image} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Partners;
