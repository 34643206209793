import React, { useState } from 'react';
import { Link } from 'react-router-dom';
/*Layouts*/
import LinkButton from '../layouts/LinkButton';

/* Assets */
import case1 from '../assets/buffet.png'; 
import case2 from '../assets/arché.png'; 
import case3 from '../assets/tremkivoa.png'; 
import case4 from '../assets/milhas.png'; 

/* Styles */
import '../styles/index.module.css'; 
import styles from '../styles/pages/cases.module.css';
import btn from '../styles/layouts/linkButton.module.css';

const Cases = () => {
  const [showAllCases, setshowAllCases] = useState(false);

  const handleLoadMoreClick = () => {
    setshowAllCases(!showAllCases);
  };

  return (
    <section className={styles.success_cases_section}>
      <h1>Cases de Sucesso</h1>
      <div className={styles.title_underline}></div> {/* Adicionando o risco */}
      <h2>Exibição de vários cases</h2>
      <p>Explore nossos serviços e veja como podemos ajudar a sua empresa a crescer</p>
      <div className={styles.case}>
        <img src={case1} alt="Buffet Sonho Real Tiradentes" className={styles.case_image} />
        <div className={styles.case_text}>
          <h3>Buffet Sonho Real Tiradentes</h3>
          <p>Desafio: Modernizar a presença online do Buffet Sonho Real. Solução: Desenvolvimento de um site intuitivo e responsivo. Resultados: Aumento significativo no número de reservas online.</p>
          {/* <Link to="/project-details/buffet-sonho-real-tiradentes">← Explore Mais</Link> */}
          <LinkButton to="/project-details/buffet-sonho-real-tiradentes" text="← Explore Mais" className="btnSecond" />
        </div>
      </div>
      <hr className={styles.divider} />
      <div className={styles.case}>
        <div className={styles.case_text}>
          <h3>Arche Empresa Junior</h3>
          <p>Desafio: Melhorar a gestão de projetos da Arche Empresa Junior. Solução: Implementação de um sistema de gerenciamento de projetos. Resultados: Maior eficiência e organização interna.</p>
          {/* <Link to="/project-details/arche-empresa-junior">Explore Mais →</Link> */}
          <LinkButton to="/project-details/arche-empresa-junior" text="← Explore Mais" className="btnSecond" />
        </div>
        <img src={case2} alt="Arche Empresa Junior" className={styles.case_image} />
      </div>
      <hr className={styles.divider} />
      <div className={styles.case}>
        <img src={case3} alt="Trem Ki Voa Aerodesign" className={styles.case_image} />
        <div className={styles.case_text}>
          <h3>Trem Ki Voa Aerodesign</h3>
          <p>Desafio: Criar um portal para apresentar os projetos de aerodesign. Solução: Desenvolvimento de um website dinâmico e interativo. Resultados: Maior visibilidade e engajamento com o público.</p>
          {/* <Link to="/project-details/trem-ki-voa-aerodesign">← Explore Mais</Link> */}
          <LinkButton to="/project-details/trem-ki-voa-aerodesign" text="← Explore Mais" className="btnSecond" />
        </div>
      </div>
      {showAllCases && (
        <div className="case">
          <hr className={styles.divider} />
          <div className={styles.case}>
            <div className={styles.case_text}>
              <h3>Milhas Gerais</h3>
              <p>Desafio: Desenvolver uma plataforma de venda de milhas. Solução: Criação de um sistema de e-commerce para comercialização de milhas. Resultados: Aumento nas vendas e na satisfação dos clientes.</p>
              {/* <Link to="/project-details/milhas-gerais">Explore Mais →</Link> */}
              <LinkButton to="/project-details/milhas-gerais" text="← Explore Mais" className="btnSecond" />
            </div>
            <img src={case4} alt="Milhas Gerais" className={styles.case_image} />
          </div>
        </div>
      )}
      <button className={btn.btn} onClick={handleLoadMoreClick}>
        {showAllCases ? 'Carregar Menos' : 'Carregar Mais'}
      </button>
      <div className={styles.more_cases}></div>
    </section>
  );
};

export default Cases;
