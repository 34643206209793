import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import MemberCarousel from '../layouts/MemberCarousel';
import styles from '../styles/pages/projectDetails.module.css';

/* Assets */
import case1 from '../assets/buffet.png'; 
import case2 from '../assets/arché.png'; 
import case3 from '../assets/tremkivoa.png'; 
import case4 from '../assets/milhas.png'; 

const projectData = {
  'buffet-sonho-real-tiradentes': {
    title: 'Buffet Sonho Real Tiradentes',
    image: case1,
    description: 'Desafio: Modernizar a presença online do Buffet Sonho Real. Solução: Desenvolvimento de um site intuitivo e responsivo. Resultados: Aumento significativo no número de reservas online.',
    members: [
      { name: 'Andre Chagas', role: 'Diretor de Projetos', image: require('../assets/Membros/Atual/Andre_Chagas.jpg') },
      { name: 'Messias Ferres', role: 'Desenvolvedor', image: require('../assets/Membros/Atual/Davi_Greco.jpg') },
      { name: 'João Antonio da Linked', role: 'Desenvolvedor', image: require('../assets/Membros/Atual/João_Antonio_da_Linked.jpg') },
      { name: 'Ana Silva', role: 'Desenvolvedora', image: require('../assets/Membros/Atual/Julia.jpg') }
    ],
  },
  'arche-empresa-junior': {
    title: 'Arche Empresa Junior',
    image: case2,
    description: 'Desafio: Melhorar a gestão de projetos da Arche Empresa Junior. Solução: Implementação de um sistema de gerenciamento de projetos. Resultados: Maior eficiência e organização interna.',
    members: [
      { name: 'Andre Chagas', role: 'Diretor de Projetos', image: require('../assets/Membros/Atual/Andre_Chagas.jpg') },
      { name: 'Messias Ferres', role: 'Desenvolvedor', image: require('../assets/Membros/Atual/Andre_Chagas.jpg') },
      { name: 'Ana Silva', role: 'Desenvolvedora', image: require('../assets/Membros/Atual/Andre_Chagas.jpg') },
      { name: 'João Antonio da Linked', role: 'Desenvolvedor', image: require('../assets/Membros/Atual/Andre_Chagas.jpg') },
      { name: 'Carlos Pereira', role: 'Desenvolvedor', image: require('../assets/Membros/Atual/Andre_Chagas.jpg') },
      { name: 'Mariana Souza', role: 'Desenvolvedora', image: require('../assets/Membros/Atual/Andre_Chagas.jpg') }
    ],
  },
  'trem-ki-voa-aerodesign': {
    title: 'Trem Ki Voa Aerodesign',
    image: case3,
    description: 'Desafio: Criar um portal para apresentar os projetos de aerodesign. Solução: Desenvolvimento de um website dinâmico e interativo. Resultados: Maior visibilidade e engajamento com o público.',
    members: [
      { name: 'Andre Chagas', role: 'Diretor de Projetos', image: require('../assets/Membros/Atual/Andre_Chagas.jpg') },
      { name: 'Messias Ferres', role: 'Desenvolvedor', image: require('../assets/Membros/Atual/Andre_Chagas.jpg') },
      { name: 'Lucas Lima', role: 'Desenvolvedor', image: require('../assets/Membros/Atual/Andre_Chagas.jpg') },
      { name: 'Fernanda Castro', role: 'Desenvolvedora', image: require('../assets/Membros/Atual/Andre_Chagas.jpg') },
      { name: 'Pedro Almeida', role: 'Desenvolvedor', image: require('../assets/Membros/Atual/Andre_Chagas.jpg') }
    ],
  },
  'milhas-gerais': {
    title: 'Milhas Gerais',
    image: case4,
    description: 'Desafio: Desenvolver uma plataforma de venda de milhas. Solução: Criação de um sistema de e-commerce para comercialização de milhas. Resultados: Aumento nas vendas e na satisfação dos clientes.',
    members: [
      { name: 'Andre Chagas', role: 'Diretor de Projetos', image: require('../assets/Membros/Atual/Andre_Chagas.jpg') },
      { name: 'Messias Ferres', role: 'Desenvolvedor', image: require('../assets/Membros/Atual/Andre_Chagas.jpg') },
      { name: 'Beatriz Ramos', role: 'Desenvolvedora', image: require('../assets/Membros/Atual/Andre_Chagas.jpg') },
      { name: 'Jorge Silva', role: 'Desenvolvedor', image: require('../assets/Membros/Atual/Andre_Chagas.jpg') },
      { name: 'Sofia Mendes', role: 'Desenvolvedora', image: require('../assets/Membros/Atual/Andre_Chagas.jpg') }
    ],
  }
};

const ProjectDetails = () => {
  const { projectId } = useParams();
  const project = projectData[projectId];

  if (!project) {
    return <p>Projeto não encontrado.</p>;
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <section className={styles.projectDetails}>
      <h1>Cases de Sucesso</h1>
      <div className={styles.title_underline}></div> {/* Adicionando o risco */}
      <Link to="/cases" className={styles.backButton} onClick={scrollToTop}>
        <FaArrowLeft />
        <span>Voltar</span>
      </Link>
      <h1 className={styles.title}>{project.title}</h1>
      <img src={project.image} alt={project.title} className={styles.projectImage} />
      <p className={styles.description}>{project.description}</p>
      <h2 className={styles.membersTitle}>Membros Participantes</h2>
      <div className={styles.title_underline}></div>
      <MemberCarousel members={project.members} />
    </section>
  );
};

export default ProjectDetails;
