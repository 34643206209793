import React from 'react';
import styles from '../styles/components/arrow.module.css';

const Arrow = ({ className, style, onClick, direction }) => (
  <div
    className={`${className} ${direction === 'next' ? styles.nextArrow : styles.prevArrow}`}
    style={{ ...style }}
    onClick={onClick}
  >
    {direction === 'next' ? (
      <svg width="24" height="24" viewBox="0 0 24 24"></svg>
    ) : (
      <svg width="24" height="24"  d="M16 5v14l-11-7z"></svg>
    )}
  </div>
);

export const NextArrow = (props) => <Arrow {...props} direction="next" />;
export const PrevArrow = (props) => <Arrow {...props} direction="prev" />;
