import React from 'react';
import Slider from 'react-slick';
import styles from '../styles/layouts/mvv.module.css';
import Card from '../layouts/Card';
import execelencia from '../assets/execelencia.png';
import lupa from '../assets/lupa.png';
import bandeira from '../assets/bandeira.png';
import pro from '../assets/pro.png';
import todos from '../assets/todos.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Mvv = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Número de slides mostrados por vez
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3, // 2 slides em telas menores que 1024px
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1, // 1 slide em telas menores que 600px
        },
      },
    ],
  };
  

  return (
    <>
      {/* Secção Misão e Visão */}
      <section className={styles.Mvv_section}>
        <h2>MVV</h2>
        <div className={styles.title_underline}></div>
        <p>Confira Nosso MVV</p> 
        <div className={styles.row}>
          <div className={styles.Mvv}>
            <h3>Missão</h3>
            <h4>O Que Nos Move</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus.</p>
          </div>
          <div className={styles.Mvv}>
            <h3>Visão</h3>
            <h4>Onde Queremos Chegar</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus.</p>
          </div>
        </div>
      </section>

      {/* Secção Valores */}
      <section className={styles.cards_section}>
        <div className={styles.title_card}>
          <h1>Nossos Valores</h1>
          <div className={styles.title_card_underline}></div>
        </div>
        <Slider {...sliderSettings} className={styles.slider}>
          <div className={styles.cardWrapper}>
            <Card img={execelencia} title='Excelência' text="" />
          </div>
          <div className={styles.cardWrapper}>
            <Card img={lupa} title='Inconformismo' text="" />
          </div>
          <div className={styles.cardWrapper}>
            <Card img={bandeira} title='Orgulho em ser Linked' text="" />
          </div>
          <div className={styles.cardWrapper}>
            <Card img={pro} title='Profissionalismo' text="" />
          </div>
          <div className={styles.cardWrapper}>
            <Card img={todos} title='Todos por um' text="" />
          </div>
        </Slider>
      </section>
    </>
  );
};

export default Mvv;
