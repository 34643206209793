import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/layouts/successCases.module.css';
import LinkButton from './LinkButton';
import case1 from '../assets/buffet.png'; 
import case2 from '../assets/arché.png'; 

const SuccessCases = () => {
  return (
    <section className={styles.success_cases_section}>
      <h2>CASES DE SUCESSO</h2>
      <div className={styles.title_underline}></div> {/* Adicionando o risco */}
      <p>Descubra como a Linked tem transformado ideias em soluções de sucesso. Confira alguns dos nossos projetos realizados:</p>
      <div className={styles.case}>
        <img src={case1} alt="Buffet Sonho Real Tiradentes" className={styles.case_image} />
        <div className={styles.case_text}>
          <h3>Buffet Sonho Real Tiradentes</h3>
          <p>Desafio: Modernizar a presença online do Buffet Sonho Real. Solução: Desenvolvimento de um site intuitivo e responsivo. Resultados: Aumento significativo no número de reservas online.</p>
          <LinkButton to="/project-details/buffet-sonho-real-tiradentes" text="← Explore Mais" className="btnSecond" />
        </div>
      </div>
      <hr className={styles.divider} />
      <div className={styles.case}>
        <div className={styles.case_text}>
          <h3>Arche Empresa Junior</h3>
          <p>Desafio: Melhorar a gestão de projetos da Arche Empresa Junior. Solução: Implementação de um sistema de gerenciamento de projetos. Resultados: Maior eficiência e organização interna.</p>
          <LinkButton to="/project-details/arche-empresa-junior" text="← Explore Mais" className="btnSecond" />
        </div>
        <img src={case2} alt="Arche Empresa Junior" className={styles.case_image} />
      </div>
      <div className={styles.more_cases}>
        <LinkButton to="/cases" text="Explorar outros cases"/>
      </div>
    </section>
  );
};

export default SuccessCases;
