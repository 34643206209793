import React from 'react';

/* layouts */
import LinkButton from '../layouts/LinkButton';
import AboutUsCompany from '../layouts/AboutUsCompany';
import Mvv from '../layouts/Mvv';
import MemberCarousels from '../layouts/MemberCarousels'; 
import Partners from '../layouts/Partners';
/*styles */
import styles from '../styles/pages/company.module.css';

/*Assets*/
import forte_megafone from '../assets/forte_megafone.jpeg';

const membersData = {
  diretores: [
    { name: 'João Antonio', role: 'Presidente', image: require('../assets/Membros/Atual/João_Antonio_da_Linked.jpg') },
    { name: 'André Chagas', role: 'Diretor de Projetos', image: require('../assets/Membros/Atual/Andre_Chagas.jpg') },
    { name: 'Lucas Costa', role: 'Diretor Comercial', image: require('../assets/Membros/Atual/Braian.jpg') },
    { name: 'Lucas Bernardes', role: 'Diretor Administrativo', image: require('../assets/Membros/Atual/Lucas_Bernardes.jpg') },
  ],
  setorProjetos: [
    { name: 'Bruno', role: 'Assessor', image: require('../assets/Membros/Atual/Bruno.jpg') },
    { name: 'Messias Feres', role: 'Assessor', image: require('../assets/Membros/Atual/Messias.jpg') },
    { name: 'Davi Greco', role: 'Assessor', image: require('../assets/Membros/Atual/Davi_Greco.jpg') },
    { name: 'Davi Gomes', role: 'Assessor', image: require('../assets/Membros/Atual/Davi_Gomes.jpg') },
    { name: 'Dilvonei', role: 'Assessor', image: require('../assets/Membros/Atual/Dilvonei.jpg') },
    { name: 'Elias', role: 'Assessor', image: require('../assets/Membros/Atual/Elias.jpg') },
    { name: 'Luiz Felipe', role: 'Assessor', image: require('../assets/Membros/Atual/Luiz_Felipe.jpg') },
    { name: 'Rivetti', role: 'Assessor', image: require('../assets/Membros/Atual/Rivetti.jpg') },
    { name: 'Vitor Niess', role: 'Assessor', image: require('../assets/Membros/Atual/Vitor_Niess.jpg') },
  ],
  setorComercial: [
    { name: 'Antonio', role: 'Assessor Comercial', image: require('../assets/Membros/Atual/Antonio.jpg') },
    { name: 'Júlia Ledo', role: 'Assessor Comercial', image: require('../assets/Membros/Atual/Julia.jpg') },
    { name: 'Guilherme', role: 'Assessor Comercial', image: require('../assets/Membros/Atual/Guilherme_Sasa.jpg') },
    { name: 'Leonardo', role: 'Assessor Comercial', image: require('../assets/Membros/Atual/Leonardo.jpg') },
    { name: 'Matheus', role: 'Assessor Comercial', image: require('../assets/Membros/Atual/Matheus.jpg') },
    { name: 'Gustavo Euller', role: 'Assessor', image: require('../assets/Membros/Atual/Gustavo_Euller.jpg') },
    { name: 'Thais Apolinário', role: 'Assessor Comercial', image: require('../assets/Membros/Atual/Thais_Apolinario.jpg') },
    { name: 'Vitor José', role: 'Assessor Comercial', image: require('../assets/Membros/Atual/Vitor_José.jpg') },
  ],
  setorGestao: [
    { name: 'Brain Alves', role: 'Assessor Administrativo', image: require('../assets/Membros/Atual/Braian.jpg') },
    { name: 'Marco', role: 'Assessor Administrativo', image: require('../assets/Membros/Atual/Marcu.jpg') },
    { name: 'Lara', role: 'Assessor Administrativo', image: require('../assets/Membros/Atual/Lara.jpg') },
    { name: 'Oscar', role: 'Assessor Administrativo', image: require('../assets/Membros/Atual/Oscar.jpg') },
  ]
};

const Company = () => {
  return (
    <>
      <section className={styles.company_container}>
        <div className={styles.text_content}>
          <h1><span>Bem vindo à Linked</span></h1>
          <h3>Somos uma empresa júnior dedicada a transformar ideias em soluções inovadoras.</h3>
          <p>Explore nossos serviços e veja como podemos ajudar a sua empresa a crescer.</p>
          <LinkButton to="/services" text="Nossos Serviços" />
          <LinkButton to="/budget" text="Faça um Orçamento" className="btnSecond" />
        </div>
        <img src={forte_megafone} alt="extra_forte" className={styles.company_image} />
      </section>
    
      <AboutUsCompany />
      <Mvv />
      <Partners/>
      <MemberCarousels members={membersData} />
    </>
  );
};

export default Company;
