import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Container from "../layouts/Container";
import styles from '../styles/components/navbar.module.css';
import logo from '../assets/logo_encurtada.png';

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className={styles.navbar}>
      <Container>
        <Link to="/">
          <img src={logo} alt="linked" className={styles.logo} />
        </Link>
        <div className={`${styles.hamburger} ${menuOpen ? styles.toggle : ''}`} onClick={toggleMenu}>
          <div className={styles.bar1}></div>
          <div className={styles.bar2}></div>
          <div className={styles.bar3}></div>
        </div>
        <ul className={`${styles.list} ${menuOpen ? styles.active : ''}`}>
          <li className={styles.item}><Link to="/">Home</Link></li>
          <li className={styles.item}><Link to="/services">Serviços</Link></li>
          <li className={styles.item}><Link to="/cases">Cases</Link></li>
          <li className={styles.item}><Link to="/company">Sobre</Link></li>
          <li className={`${styles.item} ${styles.budget}`}><Link to="/budget">Orçamento</Link></li>
        </ul>
      </Container>
    </nav>
  );
}

export default Navbar;
